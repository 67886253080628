button {
    margin-left: 10px;
}

input {
    float: center;
}

.react-bootstrap-table {
    table-layout: fixed;
    word-break: break-all;
}

.information {
    cursor:pointer;
    display: inline-block;
}

.selection-cell {
    background-color: rgb(115, 214, 118) !important;
}

th {
    background-color: white;
}

.link-handle-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.handle-overflow {
    white-space: pre-wrap;
    word-break: keep-all;
}
/* .modal-content {
    background-color: green;
} */