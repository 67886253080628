a.celllink {
    text-decoration: underline;
}

.handle-overflow {
    white-space: pre-wrap;
    word-break: keep-all;
}

table.outdate, th, td {
    border:none;
}