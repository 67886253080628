
.primary-button::before {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-box-shadow: #959595 0 2px 5px;
    -moz-box-shadow: #959595 0 2px 5px;
    border-radius: 3px;
    box-shadow: #959595 0 2px 5px;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    padding: 2px 0 0;
    position: absolute;
    top: 0;
    width: 100%; }
    
.primary-button:active::before { padding: 1px 0 0; }

.primary-button {
    -moz-box-shadow: inset 0 0 0 1px #63ad0d;
    -webkit-box-shadow: inset 0 0 0 1px #63ad0d;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background: #eee;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#eee), to(#e2e2e2));
    background: -moz-linear-gradient(#eee, #e2e2e2);
    background: linear-gradient(#eee, #e2e2e2);
    border: solid 1px #d0d0d0;
    border-bottom: solid 3px #b2b1b1;
    border-radius: 3px;
    box-shadow: inset 0 0 0 1px #f5f5f5;
    color: #555;
    display: inline-block;
    font: bold 12px Arial, Helvetica, Clean, sans-serif;
    margin: 0 25px 25px 0;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 0 #fafafa; }
    
.primary-button:not([disabled]):hover {
    background: #e4e4e4;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#e4e4e4), to(#ededed));
    background: -moz-linear-gradient(#e4e4e4, #ededed);
    background: linear-gradient(#e4e4e4, #ededed);
    border: solid 1px #c2c2c2;
    border-bottom: solid 3px #b2b1b1;
    box-shadow: inset 0 0 0 1px #efefef; }
    
.primary-button:active {
    background: #dfdfdf;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#dfdfdf), to(#e3e3e3));
    background: -moz-linear-gradient(#dfdfdf, #e3e3e3);
    background: linear-gradient(#dfdfdf, #e3e3e3);
    border: solid 1px #959595;
    box-shadow: inset 0 10px 15px 0 #c4c4c4;
    top:2px;}

.primary-button.button-green {
    background: #34A65F;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#34A65F), to(#335d30));
    background: -moz-linear-gradient(#34A65F, #335d30);
    background: linear-gradient(#34A65F, #335d30);
    border: solid 1px #1b3013;
    border-bottom: solid 3px #0a1608;
    box-shadow: inset 0 0 0 1px #53864f;
    color: #142413;
    text-shadow: 0 1px 0 #4b7b47; 
}
        
.primary-button.button-green:not([disabled]):hover {
    background: #335d30;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#335d30), to(#34A65F));
    background: -moz-linear-gradient(#335d30, #34A65F);
    background: linear-gradient(#335d30, #34A65F);
    border: solid 1px #1b3013;
    border-bottom: solid 3px #0a1608;
    box-shadow: inset 0 0 0 1px #53864f; 
}
        
.primary-button.button-green:active {
    background: #335d30;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#335d30), to(#34A65F));
    background: -moz-linear-gradient(#335d30, #34A65F);
    background: linear-gradient(#335d30, #34A65F);
    border: solid 1px #1b3013;
    box-shadow: inset 0 10px 15px 0 #294a16; 
}

.primary-button.button-red {
    background: #f97779;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#f97779), to(#ce2424));
    background: -moz-linear-gradient(#f97779, #ce2424);
    background: linear-gradient(#f97779, #ce2424);
    border: solid 1px #be2424;
    border-bottom: solid 3px #bd2524;
    box-shadow: inset 0 0 0 1px #e67e7b;
    color: #fff;
    text-shadow: 0 1px 0 #bd2524; 
}
            
.primary-button.button-red:not([disabled]):hover {
    background: #ce2424;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ce2424), to(#f97779));
    background: -moz-linear-gradient(#ce2424, #f97779);
    background: linear-gradient(#ce2424, #f97779);
    border: solid 1px #be2424;
    border-bottom: solid 3px #bd2524;
    box-shadow: inset 0 0 0 1px #e67e7b; 
}
            
.primary-button.button-red:active {
    background: #ce2424;
    background: -webkit-gradient(linear, 0 0, 0 bottom, from(#ce2424), to(#f97779));
    background: -moz-linear-gradient(#ce2424, #f97779);
    background: linear-gradient(#ce2424, #f97779);
    border: solid 1px #be2424;
    box-shadow: inset 0 10px 15px 0 #bd2524; 
}